/*
 * Mixins
 */


//==================================================
//                     MIXINS
//==================================================

//add this to the proper pseudo-element for font awesome goodness
=font-awesome($character:'')
	font-family: 'FontAwesome', sans-serif
	font-style: normal
	font-weight: normal
	text-decoration: inherit
	display: inline-block
	content: $character

// Invisible link: covers the parent area by default, hiding the text inside the "a" tag
=invisible-link
	font-size: 0
	color: transparent
	text-indent: -9999px
	position: absolute
	top: 0
	left: 0
	height: 100%
	width: 100%

//add to the proper pseudo-element
//add background-image to element in _layout.twig
//now you have a decorative icon
=icon
	background-size: contain
	background-repeat: no-repeat
	background-position: center center
	content: ''


// Animation ($type = bounce, etc.)
=animate($type, $time)
	-webkit-animation: 	$type 150ms ease-in // Chrome, Safari, Opera
	-o-animation: 			$type 150ms ease-in
	animation: 					$type 150ms ease-in

// Chrome, Safari, Opera
@-webkit-keyframes bounce
	0%
		transform: scale(0)
	90%
		transform: scale(1.3)
	100%
		transform: scale(1)

// Standard syntax
@keyframes bounce
	0%
		transform: scale(0)
	90%
		transform: scale(1.3)
	100%
		transform: scale(1)
