/*
 * Index
 */


//==================================================
//                     IMPORTS
//==================================================

@import "import/variables"
@import "import/mixins"

//==================================================
//                     CONTENT
//==================================================

body aside
	//only on the homepage does this have to be floated
	float: right

	//only homepage aside header needs blue ribbon
	h2:before
		background-image: url("../img/blue-ribbon.svg")

.introduction
	padding: 30px
	color: white
	background-color: #b2b3bc
	@media screen and (min-width: $screen-sm-min)
		background: #b2b3bc url("../img/introduction-background.png") left bottom no-repeat
		background-size: cover
	p
		font-size: 1.2em
		margin: 1em 0 2em
	.video
		position: relative
		margin-top: 30px
		figcaption
			display: flex
			position: absolute
			top: 0
			left: 0
			width: 100%
			height: 100%
			align-items: center
			justify-content: center
			flex-direction: column
			background-color: rgba(black, .15)
			transition: all 150ms ease-in-out
			opacity: .8
			font-weight: 700
			&:before
				content: ''
				display: block
				height: 80px
				width: 80px
				background: transparent url("../img/play-symbol.svg") 65% center no-repeat
				background-size: 50%
				border-radius: 100%
				border: 3px solid white
				margin-bottom: 10px
				@media screen and (min-width: $screen-md-min)
					height: 100px
					width: 100px
		a
			+invisible-link
		&:hover
			figcaption
				opacity: 1
				background-color: transparent


.quick-access
	position: relative
	.links
		margin: 0 -10px
		clear: both
		figure
			display: inline-block
			vertical-align: top
			padding: 15px 10px
			position: relative
			&:before
				content: ''
				position: absolute
				left: 10px
				top: 15px
				width: 10px
				height: calc(100% - 24px - 20px)
				background-color: $brand-primary
				z-index: 2
			&:after
				content: ''
				display: block
				position: absolute
				left: 10px
				bottom: 15px
				width: 10px
				height: 24px
				background: transparent url("../img/red-ribbon.svg") left bottom no-repeat
				background-size: contain
			img
				width: 100%
				transition: all 150ms ease-in-out
				filter: brightness(100%)
			a
				+invisible-link
			figcaption
				padding: 5px 20px
				color: $brand-primary
				font-style: italic
				font-size: 1.1em
			&:hover
				img
					filter: brightness(110%)
			@media screen and (min-width: $screen-sm-min)
				width: calc(50% - 15px)
			@media screen and (min-width: $screen-md-min)
				width: calc(100% * 1/3 - 5px)


.secondary-sections
	position: relative
	.links
		clear: both
		margin: 0 -15px
		display: flex
		flex-wrap: wrap
		figure
			display: flex
			align-items: center
			padding: 15px
			position: relative
			img
				width: 20%
				border-radius: 100%
				margin-right: 15px
				transition: all 150ms ease-in-out
				filter: brightness(100%)
			figcaption
				font-style: italic
				font-size: 1.2em
				transition: all 150ms ease-in-out
			a
				+invisible-link
			&:hover
				img
					filter: brightness(110%)
				figcaption
					color: $brand-primary
			@media screen and (min-width: $screen-md-min)
				width: 50%
