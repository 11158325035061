/* Index */
/* Variables */
/* Mixins */
@-webkit-keyframes bounce { 0% { transform: scale(0); }
  90% { transform: scale(1.3); }
  100% { transform: scale(1); } }
@keyframes bounce { 0% { transform: scale(0); }
  90% { transform: scale(1.3); }
  100% { transform: scale(1); } }
body aside { float: right; }
body aside h2:before { background-image: url("../img/blue-ribbon.svg"); }

.introduction { padding: 30px; color: white; background-color: #b2b3bc; }
@media screen and (min-width: 768px) { .introduction { background: #b2b3bc url("../img/introduction-background.png") left bottom no-repeat; background-size: cover; } }
.introduction p { font-size: 1.2em; margin: 1em 0 2em; }
.introduction .video { position: relative; margin-top: 30px; }
.introduction .video figcaption { display: flex; position: absolute; top: 0; left: 0; width: 100%; height: 100%; align-items: center; justify-content: center; flex-direction: column; background-color: rgba(0, 0, 0, 0.15); transition: all 150ms ease-in-out; opacity: 0.8; font-weight: 700; }
.introduction .video figcaption:before { content: ""; display: block; height: 80px; width: 80px; background: transparent url("../img/play-symbol.svg") 65% center no-repeat; background-size: 50%; border-radius: 100%; border: 3px solid white; margin-bottom: 10px; }
@media screen and (min-width: 992px) { .introduction .video figcaption:before { height: 100px; width: 100px; } }
.introduction .video a { font-size: 0; color: transparent; text-indent: -9999px; position: absolute; top: 0; left: 0; height: 100%; width: 100%; }
.introduction .video:hover figcaption { opacity: 1; background-color: transparent; }

.quick-access { position: relative; }
.quick-access .links { margin: 0 -10px; clear: both; }
.quick-access .links figure { display: inline-block; vertical-align: top; padding: 15px 10px; position: relative; }
.quick-access .links figure:before { content: ""; position: absolute; left: 10px; top: 15px; width: 10px; height: calc(100% - 24px - 20px); background-color: #ce0212; z-index: 2; }
.quick-access .links figure:after { content: ""; display: block; position: absolute; left: 10px; bottom: 15px; width: 10px; height: 24px; background: transparent url("../img/red-ribbon.svg") left bottom no-repeat; background-size: contain; }
.quick-access .links figure img { width: 100%; transition: all 150ms ease-in-out; filter: brightness(100%); }
.quick-access .links figure a { font-size: 0; color: transparent; text-indent: -9999px; position: absolute; top: 0; left: 0; height: 100%; width: 100%; }
.quick-access .links figure figcaption { padding: 5px 20px; color: #ce0212; font-style: italic; font-size: 1.1em; }
.quick-access .links figure:hover img { filter: brightness(110%); }
@media screen and (min-width: 768px) { .quick-access .links figure { width: calc(50% - 15px); } }
@media screen and (min-width: 992px) { .quick-access .links figure { width: calc(100% * 1/3 - 5px); } }

.secondary-sections { position: relative; }
.secondary-sections .links { clear: both; margin: 0 -15px; display: flex; flex-wrap: wrap; }
.secondary-sections .links figure { display: flex; align-items: center; padding: 15px; position: relative; }
.secondary-sections .links figure img { width: 20%; border-radius: 100%; margin-right: 15px; transition: all 150ms ease-in-out; filter: brightness(100%); }
.secondary-sections .links figure figcaption { font-style: italic; font-size: 1.2em; transition: all 150ms ease-in-out; }
.secondary-sections .links figure a { font-size: 0; color: transparent; text-indent: -9999px; position: absolute; top: 0; left: 0; height: 100%; width: 100%; }
.secondary-sections .links figure:hover img { filter: brightness(110%); }
.secondary-sections .links figure:hover figcaption { color: #ce0212; }
@media screen and (min-width: 992px) { .secondary-sections .links figure { width: 50%; } }


